<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="documents"
              :show-labels="false"
              :compact="true"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
            />
          </b-col>
        </b-row>
        <hr />

        <files-container ref="files" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import FilesContainer from "@/components/FilesContainer";

import { FORMCONTROLMODE } from "@/shared/constants.js";

export default {
  name: "Documents",
  components: {
    FilteringPanel,
    FilesContainer
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      controls: {
        module: {
          id: "input:module",
          label: "Related module",
          changed: false,
          readonly: false,
          mode: FORMCONTROLMODE.EDIT,
          options: [],
          isLoading: false,
          value: []
        },
        entity: {
          id: "input:entity",
          label: "Related entity",
          changed: false,
          readonly: false,
          mode: FORMCONTROLMODE.EDIT,
          options: [],
          isLoading: false,
          value: []
        }
      },
      filteringPanel: {
        loaded: false,
        selected: {},
        districts: [],
        filters: [
          {
            type: "select",
            dataType: "string",
            id: "select:module",
            title: "Module",
            name: "module",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            id: "select:owner",
            title: "Owner",
            name: "owner",
            trackby: "id",
            label: "label",
            options: [],
            multiple: true,
            selected: {}
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "7 years",
            title: "Created Date",
            name: "created-date"
          }
        ]
      },
      dropzoneOptions: {
        //url: 'https://httpbin.org/post',
        url: process.env.VUE_APP_API_URL + "/files",
        //url: 'https://crm2-dev.koval-distillery.com/files',
        thumbnailWidth: 50,
        maxFilesize: 3,
        headers: {
          Authorization: this.$store.getters["authToken"],
          "Cache-Control": null,
          "X-Requested-With": null
        }
      },
      dataTable: {
        options: {
          uniqueKey: "ID",
          filterByColumn: true,
          filterable: [
            "Module",
            "Entity",
            "File Name",
            "Size",
            "Created Date",
            "Owner"
          ],

          perPage: 50,
          perPageValues: [],
          saveState: true,
          storage: "local"
        },
        columns: [
          "ID",
          "Module",
          "Entity",
          "File Name",
          "Size",
          "Created Date",
          "Owner",
          "Actions"
        ],
        dataSet: [],
        totalRecords: 0
      },
      modules: {
        options: []
      },
      owners: {
        options: []
      }
    };
  },
  computed: {},
  mounted() {
    // this.getData();
  },
  methods: {
    async loadDictionaries() {
      this.getData();
    },
    async onCustomFilterSelected() {},
    vdropzoneFileAdded() {
      if (!this.$refs.module.test()) {
        this.$form.msgBoxOk("Please select related module");

        this.$refs.dropzone.removeAllFiles();
      }
      if (!this.$refs.entity.test()) {
        this.$form.msgBoxOk("Please select related entity");

        this.$refs.dropzone.removeAllFiles();
      }
    },
    vdropzoneSending(file, xhr, formData) {
      formData.append("filename", file.name);
      formData.append("filesize", file.size);
      formData.append("module_id", this.$refs.module.val.id);
      formData.append("entity_id", this.$refs.entity.val.id);
    },
    vdropzoneComplete(response) {
      if (response.status === "success") {
        this.$form.makeToastInfo(response.status);

        this.drawDataTable();
      } else {
        this.$form.makeToastError(response.status);
      }

      this.$refs.dropzone.removeAllFiles();
    },
    filterData: function(e) {
      this.filteringPanel.selected = e;

      if (!this.$refs["files"].rawData.length) return;

      let filteredData = this.$refs["files"].rawData;

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.module &&
        this.filteringPanel.selected.module.length > 0
          ? this.filteringPanel.selected.module.find(s =>
              i["Module"].includes(s.label)
            )
          : true
      );

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.owner &&
        this.filteringPanel.selected.owner.length > 0
          ? this.filteringPanel.selected.owner.find(s =>
              i["Owner"].includes(s.label)
            )
          : true
      );

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected["created-date"]
          ? !i["Created Date"] ||
            (new Date(i["Created Date"]) >=
              new Date(
                this.filteringPanel.selected["created-date"].startDate
              ) &&
              new Date(i["Created Date"]) <=
                new Date(this.filteringPanel.selected["created-date"].endDate))
          : true
      );

      this.$refs["files"].dataTable.dataSet = filteredData;
    },
    async getData(e) {
      if (e) this.filtering;

      FilteringPanel.selected = e;

      let self = this;

      self.controls.module.isLoading = true;
      this.$api.get("dictionaries/modules").then(response => {
        self.controls.module.isLoading = false;

        self.modules.options = response.map(u => ({
          id: u.id.toString(),
          label: u.name
        }));

        self.modules.options.find(
          m => m.label === "Tracktime"
        ).$isDisabled = true;
        self.modules.options.find(
          m => m.label === "Receipts"
        ).$isDisabled = true;
        self.modules.options.find(
          m => m.label === "Donations"
        ).$isDisabled = true;

        self.controls.module.options = self.modules.options;

        self.filteringPanel.filters.find(f => f.name === "module").options =
          self.modules.options;
      });

      this.$api.get("dictionaries/users").then(users => {
        self.owners.options = users.map(u => ({
          id: u.id.toString(),
          label: u.full_name.trim()
        }));
        self.filteringPanel.filters.find(f => f.name === "owner").options =
          self.owners.options;
      });
    }
  },
  watch: {}
};
</script>

<style></style>
